import { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";
import { axiosApi } from "../../axiosInstance";
import { UserContext } from "../../Contexts/UseContext";

const SubscriptionPlan = () => {
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Pricing Plans");
  }, []);

  // const featureList = [
  //   {
  //     title:
  //       "Clinic waiting area screen for token number and consulting room (Voice over).",
  //     status: "1",
  //   },
  //   {
  //     title: "Bulk Cancel or Day cancel option.",
  //     status: "1",
  //   },
  //   {
  //     title:
  //       "Pro Dashboards (Trend reports, forecasted cash in flow(Account admin)).",
  //     status: "1",
  //   },
  //   {
  //     title: "Digital prescription sent by SMS and Email.",
  //     status: "1",
  //   },
  //   {
  //     title: "Patient clinic history.",
  //     status: "1",
  //   },
  // ];

  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [yearlyPrice, setYearlyPrice] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(`/v1/plans/getallplans`);
      console.log(response.data);

      setPlans(response?.data?.Plans.rows);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(plans);

  return (
    <section id="pricing">
      <div
        className="st-height-b90 st-height-lg-b50 "
        style={{ marginTop: "40px" }}
      ></div>
      <SectionHeading
        title="Show your pricing plans"
        subTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br>Lorem Ipsum the industry's standard dummy text."
      />
      {/* <div
        className="container"
        style={{
          marginBottom: "60px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <div className="custom-loader_container">
            <span className="custom-loader"></span>
          </div>
        ) : (
          <div className="plans-slider-wrapper st-pricing-wrap">
            {plans?.map((plan) => (
              <div
                key={plans?.plan_id}
                className="st-pricing-table st-style1 plan-pricing-card"
              >
                <div className="st-pricing-head">
                  <h2 className="st-plan-price">
                    <span style={{ fontFamily: "Robot", marginRight: "5px" }}>
                      ₹
                    </span>
                    {plan?.price_per_doctor}
                  </h2>
                  <img
                    src="/shape/price-shape.svg"
                    alt="shape"
                    className="st-pricing-head-shape"
                  />
                </div>
                <div className="st-pricing-feature plan_feature_section">
                  <h2 className="st-plan-pricing-feature-title">
                    {plan?.plan_name}
                  </h2>
                  {plan?.plan_name == "Premium" ? (
                    <ul className="st-pricing-feature-list st-mp0 ps-4 pe-3">
                      {featureList.map((element, index) => (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "start",
                            textAlign: "left",
                          }}
                        >
                          <span style={{ display: "flex", marginTop: "5px" }}>
                            {element.status === "1" ? (
                              <Icon
                                style={{ color: "#37af47" }}
                                icon="fa-solid:check"
                              />
                            ) : (
                              <Icon
                                style={{ color: "#e6492d" }}
                                icon="fa-solid:times"
                              />
                            )}
                          </span>
                          <p> {element?.title}</p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="basic_plan_text st-pricing-feature-list st-mp0 ps-4 pe-3">
                      Basic Dashboards
                    </div>
                  )}
                  <div className="st-pricing-btn" style={{ marginTop: "auto" }}>
                    <Link
                      to="/owner-signup"
                      className="st-btn st-style2 st-color1 st-size-medium"
                    >
                      {" "}
                      Partner With Us
                    </Link>
                  </div>
                  <div className="st-height-b30 st-height-lg-b30" />
                </div>
              </div>
             ))}
          </div>
        )}
      </div> */}
      <div className="subscriptionplans-price-switch-container">
        <span
          style={{
            fontWeight: "600",
            color: !yearlyPrice ? "#006241" : "darkgray",
          }}
        >
          Monthly
        </span>

        <label className="subscriptionplans-price-switch-label">
          <input
            type="checkbox"
            className="subscriptionplans-price-custom_switch"
            id="subscriptionplans_price_custom_switch_checkbox1"
            onChange={() => setYearlyPrice(!yearlyPrice)}
          />
          <span className="subscriptionplans-price-switch-toggle"></span>
        </label>

        <span
          style={{
            fontWeight: "600",
            color: yearlyPrice ? "#006241" : "darkgray",
          }}
        >
          Yearly
        </span>
      </div>

      <div
        className="container"
        style={{
          marginBottom: "60px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <div className="custom-loader_container">
            <span className="custom-loader"></span>
          </div>
        ) : (
          <div className="plans-slider-wrapper st-pricing-wrap">
            {plans.length > 0 && (
              <>
                <div
                  key={plans?.plan_id}
                  className="st-pricing-table st-style1 plan-pricing-card"
                >
                  <div className="st-pricing-head">
                    <h2
                      className="st-plan-price"
                      style={{
                        fontSize: "35px",
                        marginTop: "15px",
                        padding: "0",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Robot",
                          marginRight: "5px",
                          fontSize: "20px",
                        }}
                      >
                        ₹
                      </span>
                      {yearlyPrice
                        ? plans[0]?.price_per_doctor_yearly_first_year
                        : plans[0]?.price_per_doctor_monthly_first_year}
                      <span style={{ fontSize: "20px" }}>{" /mon"}</span>
                    </h2>
                    <p
                      className="st-plan-price"
                      style={{
                        fontSize: "35px",
                        textAlign: "center",
                        marginTop: "-5px",
                        padding: "0",
                      }}
                    >
                      <span style={{ fontSize: "15px" }}>
                        ₹{" "}
                        {yearlyPrice
                          ? plans[0].price_per_doctor_yearly
                          : plans[0].price_per_doctor_monthly}
                        {" /mon"} on renewal
                      </span>
                    </p>
                    <img
                      src="/shape/price-shape.svg"
                      alt="shape"
                      className="st-pricing-head-shape"
                    />
                  </div>
                  <div className="st-pricing-feature plan_feature_section">
                    <h2 className="st-plan-pricing-feature-title">
                      {plans[0].plan_name}
                    </h2>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Basic Dashboards
                    </div>

                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Doctor Search
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Appointment Booking
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Notifications
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Basic Token Management
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Patient Queue Management
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Manual Token Calling
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#e6492d" }}
                        icon="fa-solid:times"
                      />
                      Pro Dashboards
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#e6492d" }}
                        icon="fa-solid:times"
                      />
                      Clinic Waiting Area Screen
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#e6492d" }}
                        icon="fa-solid:times"
                      />
                      <p style={{ textAlign: "left", marginBottom: "-1px" }}>
                        Bulk Appointment Management
                      </p>
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#e6492d" }}
                        icon="fa-solid:times"
                      />
                      Digital Prescription
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#e6492d" }}
                        icon="fa-solid:times"
                      />
                      <p style={{ textAlign: "left", marginBottom: "-1px" }}>
                        Enhanced Doctor Profile Management
                      </p>
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#e6492d" }}
                        icon="fa-solid:times"
                      />
                      Priority Support
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#e6492d" }}
                        icon="fa-solid:times"
                      />
                      <p style={{ textAlign: "left", marginBottom: "-1px" }}>
                        Advanced Patient Queue Management
                      </p>
                    </div>
                    <div className="basic_plan_text subscription-owner-pricing-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#e6492d" }}
                        icon="fa-solid:times"
                      />
                      Patient History
                    </div>
                    <div
                      className="st-pricing-btn"
                      style={{ marginTop: "15px" }}
                    >
                      <Link
                        to="/owner-signup"
                        className="st-btn st-style2 st-color1 st-size-medium"
                      >
                        {" "}
                        Partner With Us
                      </Link>
                    </div>
                    <div className="st-height-b30 st-height-lg-b30" />
                  </div>
                </div>

                <div
                  key={plans?.plan_id}
                  className="st-pricing-table st-style1 plan-pricing-card"
                >
                  <div className="st-pricing-head">
                    <h2
                      className="st-plan-price"
                      style={{
                        fontSize: "35px",
                        marginTop: "15px",
                        padding: "0",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Robot",
                          marginRight: "5px",
                          fontSize: "20px",
                        }}
                      >
                        ₹
                      </span>
                      {yearlyPrice
                        ? plans[1]?.price_per_doctor_yearly_first_year
                        : plans[1]?.price_per_doctor_monthly_first_year}
                      <span style={{ fontSize: "20px" }}>{" /mon"}</span>
                    </h2>
                    <p
                      className="st-plan-price"
                      style={{
                        fontSize: "35px",
                        textAlign: "center",
                        marginTop: "-5px",
                        padding: "0",
                      }}
                    >
                      <span style={{ fontSize: "15px" }}>
                        ₹{" "}
                        {yearlyPrice
                          ? plans[1].price_per_doctor_yearly
                          : plans[1].price_per_doctor_monthly}
                        {" /"}
                        mon on renewal
                      </span>
                    </p>
                    <img
                      src="/shape/price-shape.svg"
                      alt="shape"
                      className="st-pricing-head-shape"
                    />
                  </div>
                  <div className="st-pricing-feature plan_feature_section">
                    <h2 className="st-plan-pricing-feature-title">
                      {plans[1].plan_name}
                    </h2>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Pro Dashboards
                    </div>

                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Doctor Search
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Appointment Booking
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Notifications
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Basic Token Management
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Patient Queue Management
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Clinic Waiting Area Screen
                    </div>

                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      <p style={{ textAlign: "left", marginBottom: "-1px" }}>
                        Bulk Appointment Management
                      </p>
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Digital Prescription
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      <p style={{ textAlign: "left", marginBottom: "-1px" }}>
                        Enhanced Doctor Profile Management
                      </p>
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Priority Support
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      <p style={{ textAlign: "left", marginBottom: "-1px" }}>
                        Advanced Patient Queue Management
                      </p>
                    </div>
                    <div className="subscription-premium-basic_plan_text subscription-owner-pricing-premium-feature-list st-mp0 ps-4 pe-3">
                      <Icon
                        style={{ color: "#37af47" }}
                        icon="fa6-solid:check"
                      />
                      Patient History
                    </div>
                    <div
                      className="st-pricing-btn"
                      style={{ marginTop: "81px" }}
                    >
                      <Link
                        to="/owner-signup"
                        className="st-btn st-style2 st-color1 st-size-medium"
                      >
                        {" "}
                        Partner With Us
                      </Link>
                    </div>
                    <div className="st-height-b30 st-height-lg-b30" />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* <div className="container">
        <div className="row justify-content-center">
          <div className="col-auto">
            <Link to="/owner-signup" className="st-btn st-style2 st-color1 st-size-medium">
              Partner With Us
            </Link>
          </div>
        </div>
      </div> */}

      <div className="st-height-b30 st-height-lg-b30" />
    </section>
  );
};

export default SubscriptionPlan;
