import React, { useEffect, useState } from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Spacing from "../Spacing/Spacing";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import GoogleLocationPicker from "../GoogleLocationPicker/GoogleLocationPicker";
import { axiosApi, dashboardUrl } from "../../axiosInstance";
import Swal from "sweetalert2";
import Eye from "../PasswordEye/Eye";
import CloseEye from "../PasswordEye/CloseEye";
import { useParams } from "react-router-dom";

const uploadIcon = `${process.env.PUBLIC_URL}/images/upload-icon.svg`;

export default function FormWizardSample() {
  const { salespersoncode } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [butonLoading, setButonLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [specializations, setSpecializations] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState(
    sessionStorage.getItem("selectedStateId") || null
  );
  const [superOwnerInput, setSuperOwnerInput] = useState(() => {
    const savedInput = sessionStorage.getItem("superOwnerInput");
    return savedInput
      ? JSON.parse(savedInput)
      : {
          name: "",
          email: "",
          user_name: "",
          phone: "",
          salespersoncode: "",
          password: "",
          confirmPassword: "",
        };
  });

  const [superOwnerClinicInput, setSuperOwnerClinicInput] = useState(() => {
    const savedClinicInput = sessionStorage.getItem("superOwnerClinicInput");
    return savedClinicInput
      ? JSON.parse(savedClinicInput)
      : {
          name: "",
          address: "",
          phone: "",
          googleLocation: {},
          place: "",
          type: "",
          image_url: null,
        };
  });

  const [superOwnerDoctorInput, setSuperOwnerDoctorInput] = useState(() => {
    const savedDoctorInput = sessionStorage.getItem("superOwnerDoctorInput");
    return savedDoctorInput
      ? JSON.parse(savedDoctorInput)
      : {
          name: "",
          phone: "",
          address: "",
          dateOfBirth: "",
          gender: "",
          qualification: "",
          specialization: "",
          fees: "",
        };
  });

  useEffect(() => {
    if (salespersoncode) {
      setSuperOwnerInput((prevInput) => ({
        ...prevInput,
        salespersoncode,
      }));
    }
  }, [salespersoncode]);

  const handlePhoneChange = (phone) => {
    setSuperOwnerInput({ ...superOwnerInput, phone: phone });
    if (phone && typeof phone === "string") {
      const valid = isValidPhoneNumber(phone);
      if (valid) {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      }
    }
  };

  const handleClinicPhoneChange = (phone) => {
    setSuperOwnerClinicInput({ ...superOwnerClinicInput, phone: phone });
    if (phone && typeof phone === "string") {
      const valid = isValidPhoneNumber(phone);
      if (valid) {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      }
    }
  };

  const handleDoctorPhoneChange = (phone) => {
    setSuperOwnerDoctorInput({ ...superOwnerDoctorInput, phone: phone });
    if (phone && typeof phone === "string") {
      const valid = isValidPhoneNumber(phone);
      if (valid) {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      }
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setSuperOwnerInput({ ...superOwnerInput, email: value });
    if (emailRegex.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setSuperOwnerInput({ ...superOwnerInput, password: value });
    if (value >= 6) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
    if (value === superOwnerInput?.confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setSuperOwnerInput({ ...superOwnerInput, confirmPassword: value });
    if (value === superOwnerInput?.password) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  // const fetchStateList = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axiosApi.get(`/v1/owner/getAllStatesandDistrict`);
  //     setStates(response?.data?.States || []);
  //   } catch (error) {
  //     console.error(" error:", error);
  //     setStates([]);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchStateList();
  // }, []);

  // const handleStateSelect = (e) => {
  //   const selectedStateName = e?.target?.value;

  //   // Find the selected state's data
  //   const selectedState = states.find(
  //     (state) => state.name === selectedStateName
  //   );

  //   // Store both the selected state name and state_id
  //   setSelectedStateId(selectedState?.state_id || null);

  //   setSuperOwnerInput({
  //     ...superOwnerInput,
  //     state: selectedStateName,
  //   });
  // };

  // const fetchDistrictList = async () => {
  //   setSuperOwnerInput({
  //     ...superOwnerInput,
  //     district: "",
  //   });
  //   try {
  //     const response = await axiosApi.get(
  //       `/v1/owner/getAllDistrictbystate/${selectedStateId}`
  //     );
  //     setDistricts(response?.data?.District || []);
  //   } catch (error) {
  //     console.error(" error:", error);
  //     setDistricts([]);

  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedStateId) {
  //     fetchDistrictList();
  //   }
  // }, [selectedStateId]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSuperOwnerClinicInput((prevState) => ({
      ...prevState,
      image_url: file,
    }));
  };

  const fetchSpecializations = async () => {
    try {
      const response = await axiosApi.get("/v1/doctor/specializations");
      setSpecializations(response?.data?.specializations);
    } catch (error) {
      console.error("Error fetching specializations:", error);
    }
  };

  useEffect(() => {
    fetchSpecializations();
  }, []);

  const handleSpecializationChange = (e) => {
    setSuperOwnerDoctorInput({
      ...superOwnerDoctorInput,
      specialization: e.target.value,
    });
  };

  const handleFeesChange = (e) => {
    setSuperOwnerDoctorInput({
      ...superOwnerDoctorInput,
      fees: e.target.value.replace(/\D/g, ""),
    });
  };

  // owner validation
  const ownerValidate = () => {
    const newErrors = {};
    const email = superOwnerInput.email;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let errorMessages = [];

    // Validate phone number
    if (superOwnerInput.phone && typeof superOwnerInput.phone === "string") {
      const valid = isValidPhoneNumber(superOwnerInput.phone);
      if (!valid) {
        newErrors.phone = "Please enter a valid phone number";
        errorMessages.push("Please enter a valid phone number");
      }
    }

    // Validate email
    if (!emailRegex.test(email)) {
      newErrors.email = "Enter a valid email address";
      errorMessages.push("Enter a valid email address");
    }

    // Validate password length
    if (superOwnerInput.password && superOwnerInput.password.length < 6) {
      newErrors.password = "Password should contain minimum 6 characters";
      errorMessages.push("Password should contain minimum 6 characters");
    }

    // Validate password confirmation
    if (
      superOwnerInput.password &&
      superOwnerInput.password !== superOwnerInput.confirmPassword
    ) {
      newErrors.confirmPassword = "Passwords do not match";
      errorMessages.push("Passwords do not match");
    }

    if (errorMessages.length > 0) {
      toast.warning(errorMessages[0]); // Show only the first error
    }

    setErrors(newErrors);
    return errorMessages.length === 0;
  };

  // clinic validation
  const clinicValidate = () => {
    const newErrors = {};

    let errorMessages = [];

    // Validate phone number
    if (
      superOwnerClinicInput.phone &&
      typeof superOwnerClinicInput.phone === "string"
    ) {
      const valid = isValidPhoneNumber(superOwnerClinicInput.phone);
      if (!valid) {
        newErrors.phone = "Please enter a valid phone number";
        errorMessages.push("Please enter a valid phone number");
      }
    }

    if (errorMessages.length > 0) {
      toast.warning(errorMessages[0]); // Show only the first error
    }

    setErrors(newErrors);
    return errorMessages.length === 0;
  };

  // doctor validation
  const doctorValidate = () => {
    const newErrors = {};

    let errorMessages = [];

    // Validate phone number
    if (
      superOwnerDoctorInput.phone &&
      typeof superOwnerDoctorInput.phone === "string"
    ) {
      const valid = isValidPhoneNumber(superOwnerDoctorInput.phone);
      if (!valid) {
        newErrors.phone = "Please enter a valid phone number";
        errorMessages.push("Please enter a valid phone number");
      }
    }

    if (errorMessages.length > 0) {
      toast.warning(errorMessages[0]); // Show only the first error
    }

    setErrors(newErrors);
    return errorMessages.length === 0;
  };

  const handleBackClick = (handlePrevious) => {
    handlePrevious();
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const backTemplate = (handlePrevious) => {
    return (
      <button
        className="back-button"
        onClick={() => handleBackClick(handlePrevious)}
      >
        back
      </button>
    );
  };

  const handleNext = (nextFunction) => {
    // Check validation based on the currentIndex
    if (currentIndex === 0) {
      // Validation for Super Owner
      if (
        !superOwnerInput.name ||
        !superOwnerInput.email ||
        !superOwnerInput.phone ||
        !superOwnerInput.password ||
        !superOwnerInput.confirmPassword
      ) {
        toast.warning("Please fill all fields");
        return;
      }
      if (!ownerValidate()) {
        return;
      }
    } else if (currentIndex === 1) {
      // Validation for Super Clinic
      if (
        !superOwnerClinicInput.name ||
        !superOwnerClinicInput.address ||
        !superOwnerClinicInput.phone ||
        !superOwnerClinicInput.place ||
        !superOwnerClinicInput.type ||
        !superOwnerClinicInput.image_url ||
        !superOwnerClinicInput.googleLocation.lat ||
        !superOwnerClinicInput.googleLocation.long
      ) {
        toast.warning("Please fill all fields");
        return;
      }
      if (!clinicValidate()) {
        return;
      }
    }

    // Call the next function if all validations pass
    nextFunction();
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handleComplete = async () => {
    // Validation for Super Doctor
    if (
      !superOwnerDoctorInput.name ||
      !superOwnerDoctorInput.phone ||
      !superOwnerDoctorInput.address ||
      !superOwnerDoctorInput.dateOfBirth ||
      !superOwnerDoctorInput.gender ||
      !superOwnerDoctorInput.qualification ||
      !superOwnerDoctorInput.specialization ||
      !superOwnerDoctorInput.fees
    ) {
      toast.warning("Please fill all fields");
      return;
    }
    if (!doctorValidate()) {
      return;
    }
    // const ownerfullname = `${superOwnerInput?.firstname} ${superOwnerInput?.lastname}`;

    setButonLoading(true);

    const { confirmPassword, ...rest } = superOwnerInput;

    try {
      const ownerdata = {
        ...rest,
        user_name: superOwnerInput.email,
      };

      if (!ownerdata.salespersoncode) {
        delete ownerdata.salespersoncode;
      }

      // clinic
      const formData = new FormData();
      formData.append("clinic_name", superOwnerClinicInput.name);
      formData.append("clinic_address", superOwnerClinicInput.address);
      formData.append("clinic_phone", superOwnerClinicInput.phone);
      formData.append("place", superOwnerClinicInput.place);
      formData.append("type", superOwnerClinicInput.type);
      formData.append(
        "googleLocation",
        JSON.stringify(superOwnerClinicInput.googleLocation)
      );
      if (superOwnerClinicInput.image_url) {
        formData.append("image_url[]", superOwnerClinicInput.image_url);
      }

      //Doctor
      const doctorData = {
        name: superOwnerDoctorInput?.name,
        phone: superOwnerDoctorInput?.phone,
        address: superOwnerDoctorInput?.address,
        gender: superOwnerDoctorInput?.gender,
        dateOfBirth: superOwnerDoctorInput?.dateOfBirth,
        qualification: superOwnerDoctorInput?.qualification,
        specialization: superOwnerDoctorInput?.specialization,
        fees: superOwnerDoctorInput?.fees,
      };

      const response = await axiosApi.post("/v1/auth/sign-up", ownerdata);

      const accessToken = response.data.accessToken;

      // Call createclinic and createDr APIs with the access token in the headers
      await axiosApi.post("/v1/superowner/createclinic", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`, // Include the access token
        },
      });

      await axiosApi.post("/v1/superowner/createDr", doctorData, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
        },
      });

      Swal.fire({
        title: "Signup successful!",
        text: "Click to continue to your Dashboard",
        icon: "success",
        confirmButtonText: "Go to Dashboard",
      }).then((result) => {
        window.location.href = dashboardUrl;
      });

      setTimeout(() => {
        window.location.href = dashboardUrl;
      }, 6000);
    } catch (error) {
      console.error("Signup error:", error);
      if (error?.response && error?.response?.status === 403) {
        toast.error("Email already exists. Please use a different email!");
      } else {
        toast.error("Signup failed. Please try again!");
      }
      setButonLoading(false);
    } finally {
      setButonLoading(false);
    }
  };

  function SolarUserLinear() {
    return (
      <img
        src="https://cdn-icons-png.freepik.com/256/14419/14419571.png?ga=GA1.1.1563912819.1689775412&semt=ais_hybrid"
        alt="hospital-icon"
        style={{
          width: "20px",
          height: "20px",
          color: "white",
          filter: "invert(100%)",
        }}
      />
    );
  }
  function vsCodeIcon() {
    return (
      <img
        src="https://cdn-icons-png.freepik.com/256/16970/16970864.png?ga=GA1.1.1563912819.1689775412&semt=ais_hybrid"
        alt="doctor-icon"
        style={{
          width: "20px",
          height: "20px",
          filter: "invert(80%)",
        }}
      />
    );
  }

  return (
    <>
      <FormWizard
        stepSize="xs"
        shape="circle"
        color="#006241"
        onComplete={handleComplete}
        backButtonTemplate={backTemplate}
        nextButtonTemplate={(next) => (
          <button className="base-button" onClick={() => handleNext(next)}>
            next
          </button>
        )}
        finishButtonTemplate={(handleComplete) => (
          <button
            className="finish-button"
            onClick={handleComplete}
            disabled={butonLoading}
          >
            {butonLoading ? "Submitting..." : "finish"}
          </button>
        )}
      >
        <FormWizard.TabContent title="Personal Details" icon="ti-user">
          <Spacing lg={50} md={50} />
          <h3>Personal Details</h3>
          <div className="container signup_main">
            <div className="booking_container">
              <div className="booking_form_card">
                <form onSubmit={handleComplete} className="signup_form">
                  <Spacing lg={30} md={20} />
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="super-name"
                      placeholder="Name"
                      required
                      value={superOwnerInput?.name}
                      onChange={(e) =>
                        setSuperOwnerInput({
                          ...superOwnerInput,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>

                  <PhoneInput
                    international
                    defaultCountry="IN"
                    countryCallingCodeEditable={false}
                    value={superOwnerInput?.phone || ""}
                    onChange={handlePhoneChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={isFocused ? "active_phone_input" : ""}
                  />
                  {errors?.phone && (
                    <p className="text-danger text-start">{errors?.phone}</p>
                  )}

                  <div className="mt-3">
                    <input
                      type="email"
                      className="form-control"
                      id="super-email"
                      placeholder="Email"
                      required
                      value={superOwnerInput?.email}
                      onChange={handleEmailChange}
                      autoComplete="off"
                    />
                  </div>
                  {errors?.email && (
                    <p className="text-danger text-start">{errors?.email}</p>
                  )}
                  {/* <div className="mt-3 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="super-username"
                      placeholder="Username"
                      required
                      value={superOwnerInput?.email}
                      onChange={(e) =>
                        setSuperOwnerInput({
                          ...superOwnerInput,
                          email: e.target.value,
                        })
                      }
                      readOnly
                    />
                  </div> */}
                  {/* <div className="mb-3">
                    <textarea
                      className="form-control"
                      id="super-address"
                      rows="3"
                      placeholder="Address"
                      required
                      value={superOwnerInput?.address}
                      onChange={(e) =>
                        setSuperOwnerInput({
                          ...superOwnerInput,
                          address: e.target.value,
                        })
                      }
                    ></textarea>
                  </div> */}
                  {/* <div className="mb-3 mt-3">
                    <select
                      className={`form-control form-select ${
                        !superOwnerInput?.state && "form-textcolor"
                      }`}
                      id="super-state"
                      placeholder="State"
                      required
                      value={superOwnerInput?.state}
                      onChange={(e) => {
                        handleStateSelect(e);
                      }}
                    >
                      <option className="" value="" disabled>
                        Select State
                      </option>
                      {states?.map((state) => (
                        <option key={state?.state_id} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div> */}

                  {/* <div className="mb-3 mt-3">
                    <select
                      className={`form-control form-select district-select ${
                        !superOwnerInput?.district && "form-textcolor"
                      }`}
                      id="districts"
                      placeholder="District"
                      required
                      value={superOwnerInput?.district || ""}
                      onChange={(e) =>
                        setSuperOwnerInput({
                          ...superOwnerInput,
                          district: e.target.value,
                        })
                      }
                      disabled={districts.length === 0}
                    >
                      <option value="" disabled={!superOwnerInput?.district}>
                        Select District
                      </option>
                      {districts.map((district) => (
                        <option key={district?.id} value={district?.district}>
                          {district.district}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  {/* <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="super-city"
                      placeholder="City"
                      required
                      value={superOwnerInput?.city}
                      onChange={(e) =>
                        setSuperOwnerInput({
                          ...superOwnerInput,
                          city: e.target.value,
                        })
                      }
                    />
                  </div> */}

                  <div className="password-input-container mt-3">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id="super-password"
                      placeholder="Password"
                      required
                      value={superOwnerInput?.password}
                      onChange={handlePasswordChange}
                    />
                    <div
                      className="icon-container"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Eye /> : <CloseEye />}
                    </div>
                  </div>
                  {errors.password && (
                    <p className="text-danger text-start">{errors.password}</p>
                  )}

                  <div className="password-input-container mt-3">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className={`form-control ${
                        errors?.confirmPassword ? "is-invalid" : ""
                      }`}
                      id="super-confirm-password"
                      placeholder="Confirm Password"
                      required
                      value={superOwnerInput?.confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <div
                      className="icon-container"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <Eye /> : <CloseEye />}
                    </div>
                  </div>
                  {errors.confirmPassword && (
                    <p className="text-danger text-start">
                      {errors.confirmPassword}
                    </p>
                  )}

                  <Spacing lg={20} md={20} />
                </form>
              </div>
            </div>
            <Spacing lg={40} md={30} />
          </div>
        </FormWizard.TabContent>

        {/* Clinic Details starts here */}
        <FormWizard.TabContent title="Clinic Details" icon={SolarUserLinear()}>
          <Spacing lg={50} md={50} />
          <h3>Clinic Details</h3>
          <>
            <div className="booking_container">
              <div className="booking_form_card">
                <form onSubmit={handleComplete} className="signup_form">
                  <Spacing lg={30} md={20} />
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="firstname"
                      placeholder="Name"
                      required
                      value={superOwnerClinicInput?.name}
                      onChange={(e) =>
                        setSuperOwnerClinicInput({
                          ...superOwnerClinicInput,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      id="address"
                      rows="3"
                      placeholder="Address"
                      required
                      value={superOwnerClinicInput?.address}
                      onChange={(e) =>
                        setSuperOwnerClinicInput({
                          ...superOwnerClinicInput,
                          address: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>

                  <PhoneInput
                    international
                    defaultCountry="IN"
                    countryCallingCodeEditable={false}
                    value={superOwnerClinicInput?.phone || ""}
                    onChange={handleClinicPhoneChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={isFocused ? "active_phone_input" : ""}
                  />
                  {errors?.phone && (
                    <p className="text-danger text-start">{errors?.phone}</p>
                  )}

                  <div className="mb-3 mt-3 border rounded">
                    <GoogleLocationPicker
                      clinicInput={superOwnerClinicInput}
                      setClinicInput={setSuperOwnerClinicInput}
                    />
                  </div>

                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="place"
                      placeholder="Place"
                      required
                      value={superOwnerClinicInput?.place}
                      onChange={(e) =>
                        setSuperOwnerClinicInput({
                          ...superOwnerClinicInput,
                          place: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="type"
                      placeholder="Type"
                      required
                      value={superOwnerClinicInput?.type}
                      onChange={(e) =>
                        setSuperOwnerClinicInput({
                          ...superOwnerClinicInput,
                          type: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label className="SuperOwnerSignup-filepicker">
                      <input
                        type="file"
                        id="file-picker"
                        aria-label="File browser example"
                        onChange={handleFileChange}
                        className="SuperOwnerSignup-file-input"
                      />
                      {superOwnerClinicInput?.image_url ? (
                        <p>{superOwnerClinicInput?.image_url?.name}</p>
                      ) : (
                        <span>Upload File</span>
                      )}
                      <div className="SuperOwnerSignup-icon_wrapper">
                        <img src={uploadIcon} alt="Upload" />
                      </div>
                    </label>
                  </div>
                </form>
              </div>
            </div>
            <Spacing lg={40} md={30} />
          </>
        </FormWizard.TabContent>
        {/* Clinic Details ends */}

        {/* Doctor Details starts here */}
        <FormWizard.TabContent title="Doctor Details" icon={vsCodeIcon()}>
          <Spacing lg={50} md={50} />
          <h3>Doctor Details</h3>
          <div className="container signup_main">
            <div className="booking_container">
              <div className="booking_form_card">
                <form className="signup_form">
                  <Spacing lg={30} md={20} />
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      required
                      value={superOwnerDoctorInput?.name}
                      onChange={(e) =>
                        setSuperOwnerDoctorInput({
                          ...superOwnerDoctorInput,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>

                  {/* <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      placeholder="Last Name"
                      required
                      value={superOwnerDoctorInput?.lastname}
                      onChange={(e) =>
                        setSuperOwnerDoctorInput({
                          ...superOwnerDoctorInput,
                          lastname: e.target.value,
                        })
                      }
                    />
                  </div> */}

                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      id="address"
                      rows="3"
                      placeholder="Address"
                      required
                      value={superOwnerDoctorInput?.address}
                      onChange={(e) =>
                        setSuperOwnerDoctorInput({
                          ...superOwnerDoctorInput,
                          address: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>

                  <PhoneInput
                    international
                    defaultCountry="IN"
                    countryCallingCodeEditable={false}
                    value={superOwnerDoctorInput?.phone || ""}
                    onChange={handleDoctorPhoneChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={isFocused ? "active_phone_input" : ""}
                  />
                  {errors?.phone && (
                    <p className="text-danger text-start">{errors?.phone}</p>
                  )}

                  <div className="mb-3 mt-3">
                    <select
                      className={`form-control form-select ${
                        !superOwnerDoctorInput?.gender && "form-textcolor"
                      }`}
                      id="gender"
                      required
                      value={superOwnerDoctorInput?.gender}
                      onChange={(e) =>
                        setSuperOwnerDoctorInput({
                          ...superOwnerDoctorInput,
                          gender: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <input
                      type="date"
                      className={`form-control form-select ${
                        !superOwnerDoctorInput?.dateOfBirth && "form-textcolor"
                      }`}
                      id="dateOfBirth"
                      required
                      value={superOwnerDoctorInput?.dateOfBirth}
                      onChange={(e) =>
                        setSuperOwnerDoctorInput({
                          ...superOwnerDoctorInput,
                          dateOfBirth: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="Qualification"
                      placeholder="Qualification"
                      required
                      value={superOwnerDoctorInput?.qualification}
                      onChange={(e) =>
                        setSuperOwnerDoctorInput({
                          ...superOwnerDoctorInput,
                          qualification: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <select
                      className="form-control form-select p-2 "
                      id="Specialization"
                      required
                      style={{ color: "grey" }}
                      value={superOwnerDoctorInput?.specialization}
                      onChange={handleSpecializationChange}
                    >
                      <option value="">Select Specialization</option>
                      {specializations?.map((spec) => (
                        <option key={spec?.id} value={spec.name}>
                          {spec?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      id="fees"
                      placeholder="Fees"
                      required
                      value={superOwnerDoctorInput?.fees}
                      onChange={handleFeesChange}
                    />
                  </div>
                </form>
              </div>
            </div>
            <Spacing lg={40} md={30} />

            <ToastContainer position="top-center" autoClose={2000} />
          </div>
        </FormWizard.TabContent>
        {/* Doctor Details ends */}
      </FormWizard>
      {/* add style */}
      <style>{`
          @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
          .wizard-card-footer {
            display: flex;
            justify-content: space-between; 
            align-items: center; 
            margin-top: 10px;
            width: 100%; 
            box-sizing: border-box; 
          }

          .base-button,
          .back-button,
          .finish-button {
            width: 12rem;
            height: 2.5rem;
            background-color: #006241;
            border: none;
            color: white;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            cursor: pointer;
            border-radius: 5px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
            transition: background-color 0.3s ease;
          }

          .base-button {
            margin-left: auto;
          }

          .back-button {
            margin-right: auto; 
          }

          @media (max-width: 370px) {
           .wizard-card-footer {
             flex-direction: column; 
             justify-content: center;
             gap: 5px
           }

          .base-button,
          .back-button,
          .finish-button {
            width: 100%; 
          }
          }

          
          .base-button:hover {
          background-color: green;
          }
          
          .base-button:focus {
          outline: none;
          }
          
          .base-button:active {
          transform: translateY(2px);
          }

        // .finish-button{
        //   width: 12rem;
        //   height: 2.5rem;
        //   background-color: #006241;
        //   border: none;
        //   color: white;
        //   text-align: center;
        //   text-decoration: none;
        //   display: inline-block;
        //   font-size: 16px;
        //   cursor: pointer;
        //   margin-right: auto;
        //   margin-left: auto;
        //   border-radius: 5px;
        //   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        //   transition: background-color 0.3s ease;
        // }

        

        .finish-button:hover {
          background-color: darkgreen;
          }
        
        .finish-button:focus {
          outline: none;
         }
          
        .finish-button:active {
          transform: translateY(2px);
         }
        `}</style>
    </>
  );
}
