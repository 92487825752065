import React, { useContext, useEffect, useState } from "react";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi, dashboardUrl } from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";
import Eye from "../../Components/PasswordEye/Eye";
import CloseEye from "../../Components/PasswordEye/CloseEye";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import FormWizardSample from "../../Components/SuperOwnerSignup/SuperOwnerSignup";

const OwnerSignupPage = () => {
  const { salespersoncode } = useParams();
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Register as Owner");
  }, []);

  const [input, setInput] = useState({
    // firstname: "",
    // lastname: "",
    name: "",
    email: "",
    // user_name: "",
    phone: "",
    // address: "",
    // country: "India",
    // state: "",
    // district: "",
    // city: "",
    salespersoncode: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  // const [loading, setLoading] = useState(false);
  const [butonLoading, setButonLoading] = useState(false);
  // const [states, setStates] = useState([]);
  // const [districts, setDistricts] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);

  useEffect(() => {
    if (salespersoncode) {
      setInput((prevInput) => ({
        ...prevInput,
        salespersoncode,
      }));
    }
  }, [salespersoncode]);

  const validate = () => {
    const newErrors = {};
    const email = input.email;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let errorMessages = [];

    // Validate phone number
    if (input.phone && typeof input.phone === "string") {
      const valid = isValidPhoneNumber(input.phone);
      console.log(valid);
      
      if (!valid) {
        newErrors.phone = "Please enter a valid phone number";
        errorMessages.push("Please enter a valid phone number");
      }
    }

    // Validate email
    if (!emailRegex.test(email)) {
      newErrors.email = "Enter a valid email address";
      errorMessages.push("Enter a valid email address");
    }

    // Validate password length
    if (input.password.length < 6) {
      newErrors.password = "Password should contain minimum 6 characters";
      errorMessages.push("Password should contain minimum 6 characters");
    }

    // Validate password confirmation
    if (input.password !== input.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      errorMessages.push("Passwords do not match");
    }

    if (errorMessages.length > 0) {
      toast.warning(errorMessages[0]); // Show only the first error
    }

    setErrors(newErrors);
    return errorMessages.length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !input.name ||
      !input.email ||
      !input.phone ||
      !input.password ||
      !input.confirmPassword
    ) {
      toast.warning("Please fill in all input fields");
      return;
    }

    // const fullname = `${input?.firstname} ${input?.lastname}`;

    if (validate()) {
      setButonLoading(true);

      // const {firstname, lastname, ...rest} = input;

      const { confirmPassword, ...rest } = input;

      try {
        const data = {
          ...rest,
          user_name: input.email,
        };

        if (!data.salespersoncode) {
          delete data.salespersoncode;
        }

        console.log(data);
        

        const response = await axiosApi.post("/v1/auth/sign-up", data);
        
        Swal.fire({
          title: "Signup successful!",
          text: "Click to continue to your Dashboard",
          icon: "success",
          confirmButtonText: "Go to Dashboard",
        }).then((result) => {
          window.location.href = dashboardUrl;
        });

        setButonLoading(false);
        setInput({
          name: "",
          email: "",
          phone: "",
          salespersoncode: "",
          password: "",
          confirmPassword: "",
        });

        setTimeout(() => {
          window.location.href = dashboardUrl;
        }, 6000);
      } catch (error) {
        console.error("Signup error:", error);
        if (error?.response && error?.response?.status === 403) {
          toast.error("Email already exists. Please use a different email!");
        } else {
          toast.error("Signup failed. Please try again!");
        }
        setButonLoading(false);
      } finally {
        setButonLoading(false);
      }
    }
  };

  const handlePhoneChange = (phone) => {
    setInput({ ...input, phone: phone });
    if (phone && typeof phone === "string") {
      const valid = isValidPhoneNumber(phone);
      if (valid) {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      }
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setInput({ ...input, email: value });
    if (emailRegex.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setInput({ ...input, password: value });
    if (value >= 6) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
    if (value === input?.confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setInput({ ...input, confirmPassword: value });
    if (value === input?.password) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  // const fetchStateList = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axiosApi.get(`/v1/owner/getAllStatesandDistrict`);
  //     setStates(response?.data?.States || []);
  //   } catch (error) {
  //     console.error(" error:", error);
  //     setStates([]);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchStateList();
  // }, []);

  // const handleStateSelect = (e) => {
  //   const selectedStateName = e.target.value;
  //   setInput({ ...input, state: selectedStateName, district: "" });
  //   const selectedState = states.find(
  //     (state) => state.name === selectedStateName
  //   );
  //   const selectedStateId = selectedState?.state_id;
  //   if (selectedStateId) {
  //     fetchDistrictList(selectedStateId);
  //   } else {
  //     setDistricts([]);
  //   }
  // };

  // const fetchDistrictList = async (stateid) => {
  //   try {
  //     const response = await axiosApi.get(
  //       `/v1/owner/getAllDistrictbystate/${stateid}`
  //     );
  //     setDistricts(response?.data?.District || []);
  //   } catch (error) {
  //     console.error(" error:", error);
  //     setDistricts([]);

  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="container signup_main">
      <Spacing lg={90} md={90} />
      {/* <div className="mb-3 text-center">
        <input
          type="checkbox"
          id="formToggle"
          checked={false}
          onChange={() => setIsFormVisible(!isFormVisible)}
        />
        <label htmlFor="formToggle" className="ms-2">
          {!isFormVisible ? "Sign as Owner" : "Sign as Super Owner"}
        </label>
      </div> */}
      <div className="ownerSignUpPage-checkbox-wrapper-2 text-center d-flex align-items-center justify-content-center">
        <input
          type="checkbox"
          id="formToggle"
          className="ownerSignUpPage-ikxBAC"
          onChange={() => setIsFormVisible(!isFormVisible)}
        />
        <label htmlFor="formToggle" className="ms-2 ownerSignUpPage-label">
          {!isFormVisible ? "Signup as Owner" : "Signup as Super Owner"}
        </label>
      </div>

      {isFormVisible && (
        <div className="booking_container">
          <div className="booking_form_card">
            <form onSubmit={handleSubmit} className="signup_form">
              <h3
                style={{ textAlign: "center" }}
                className="booking_form_card_title"
              >
                Sign up
              </h3>
              <Spacing lg={30} md={20} />
              {/* <div className="mb-3">
                <input
                  type="checkbox"
                  id="superOwner"
                  className="form-check-input"
                  checked={input?.isSuperOwner || false}
                  onChange={(e) =>
                    setInput({ ...input, isSuperOwner: e.target.checked })
                  }
                />
                <label htmlFor="superOwner" className="form-check-label ms-2">
                  SignUp as SuperOwner
                </label>
              </div> */}
              {/* <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="First Name"
                  required
                  value={input?.firstname}
                  onChange={(e) =>
                    setInput({ ...input, firstname: e.target.value })
                  }
                />
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="Last Name"
                  required
                  value={input?.lastname}
                  onChange={(e) =>
                    setInput({ ...input, lastname: e.target.value })
                  }
                />
              </div> */}

              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  required
                  value={input?.name}
                  onChange={(e) =>
                    setInput({ ...input, name: e.target.value })
                  }
                />
              </div>

              <PhoneInput
                international
                defaultCountry="IN"
                countryCallingCodeEditable={false}
                value={input?.phone || ""}
                onChange={handlePhoneChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={isFocused ? "active_phone_input" : ""}
              />
              {errors?.phone && <p className="text-danger">{errors?.phone}</p>}

              <div className="mt-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email or Username"
                  required
                  value={input?.email}
                  onChange={handleEmailChange}
                  autoComplete="off"
                />
              </div>
              {errors?.email && <p className="text-danger">{errors?.email}</p>}
              {/* <div className="mt-3 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Username"
                  required
                  value={input?.email}
                  onChange={(e) =>
                    setInput({ ...input, email: e.target.value })
                  }
                  readOnly
                />
              </div> */}

              {/* <div className="mb-3">
                <textarea
                  className="form-control"
                  id="address"
                  rows="3"
                  placeholder="Address"
                  required
                  value={input?.address}
                  onChange={(e) =>
                    setInput({ ...input, address: e.target.value })
                  }
                ></textarea>
              </div> */}

              {/* <div className="mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="country"
                placeholder="Country"
                required
                value={input?.country}
                readOnly
                onChange={(e) =>
                  setInput({ ...input, country: e.target.value })
                }
                autoComplete="off"
              />
            </div> */}
              {/* <div className="mb-3 mt-3">
                <select
                  className={`form-control form-select ${
                    !input?.state && "form-textcolor"
                  }`}
                  id="state"
                  placeholder="State"
                  required
                  value={input?.state}
                  onChange={(e) => {
                    handleStateSelect(e);
                    setInput({ ...input, state: e.target.value });
                  }}
                >
                  <option className="" value="" disabled>
                    Select State
                  </option>
                  {states.map((state) => (
                    <option key={state?.state_id} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div> */}

              {/* <div className="mb-3 mt-3">
                <select
                  className={`form-control form-select district-select ${
                    !input?.district && "form-textcolor"
                  }`}
                  id="districts"
                  placeholder="District"
                  required
                  value={input?.district || ""}
                  onChange={(e) =>
                    setInput({ ...input, district: e.target.value })
                  }
                  disabled={districts.length === 0}
                >
                  <option value="" disabled={!input?.district}>
                    Select District
                  </option>
                  {districts.map((district) => (
                    <option key={district?.id} value={district?.district}>
                      {district.district}
                    </option>
                  ))}
                </select>
              </div> */}
              {/* <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="City"
                  required
                  value={input?.city}
                  onChange={(e) => setInput({ ...input, city: e.target.value })}
                />
              </div> */}

              <div className="password-input-container mt-3">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  required
                  value={input?.password}
                  onChange={handlePasswordChange}
                />
                <div
                  className="icon-container"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Eye /> : <CloseEye />}
                </div>
              </div>
              {errors.password && (
                <p className="text-danger">{errors.password}</p>
              )}

              <div className="password-input-container mt-3">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className={`form-control ${
                    errors?.confirmPassword ? "is-invalid" : ""
                  }`}
                  id="confirm-password"
                  placeholder="Confirm Password"
                  required
                  value={input?.confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <div
                  className="icon-container"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <Eye /> : <CloseEye />}
                </div>
              </div>
              {errors.confirmPassword && (
                <p className="text-danger">{errors.confirmPassword}</p>
              )}

              <Spacing lg={40} md={30} />
              <div className="booking_form_card_btn_wrapper">
                <button
                  type="submit"
                  className="signup_submit_btn"
                  disabled={butonLoading}
                >
                  {butonLoading ? <span className="loader"></span> : "Sign up"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isFormVisible && <Spacing lg={100} md={80} />}
      <ToastContainer position="top-center" autoClose={2000} />

      {!isFormVisible && <FormWizardSample />}
    </div>
  );
};

export default OwnerSignupPage;
